import { TUser } from "../../types/user.type";
import { instanceBackend } from "./base";
import { TResponse } from "./types";

type TVerifySMSCode = {
  companyId: number;
  phone: string;
  fullname?: string;
};

export const verifySMSCode = async ({ companyId, ...body }: TVerifySMSCode) => {
  return await instanceBackend.post<TResponse>(`book_code/${companyId}`, { ...body });
};

type TAuthByCode = {
  phone: string;
  code: string;
};

export const authByCode = async ({ phone, code }: TAuthByCode) => {
  return await instanceBackend.post<TResponse<TUser>>(`user/auth`, { phone, code });
};

type TAuthByCode1 = {
  phone: string;
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const sendPhone = async ({ phone }: TAuthByCode1) => {
  return await instanceBackend.post<TResponse<TUser>>(`user`, {
    username: "MissisLaser" + getRandomInt(999999999999999),
    password: "xxxxxxx",
    sex: 2,
    phone: "7" + phone,
  });
};


export const checkPhoneNumber = async (data: { phone: string }) => {
  return await instanceBackend.post<{ success: boolean; count: number }>(`shedule/check-seances`, {
    phone: "7" + data.phone,
  });
};

export const checkRegCount = async () => {
  return await instanceBackend.get(`db/full/leads-count?id=658c03cdae79a500315b9d5f`,
    { headers: { Authorization: '$2b$08$4FRAyjdWdgQ0vhOSVP8NN.u5Na7a50T2krexaojtAYqPYz/6ryuAe' } })
};

export const sendLeadToVakas = async (data: any) => {
  return await instanceBackend.post(`db/20`, data);
};

